import { AuthLevel } from '@getstep/proto-lib/rpc'
import { AuthenticationFailureError } from '@getstep/sdk/dist/http/auth/AuthenticationFailureError'
import {
    ErrorCode,
    ErrorStatus,
    isHttpError,
} from '@getstep/sdk/dist/http/HttpError'
import { mustExist } from '@getstep/sdk/dist/util/Assert'
import {
    action,
    computed,
    makeAutoObservable,
    observable,
    transaction,
} from 'mobx'

import { EmailVerificationStore, PhoneVerificationStore } from '.'
import { LoginStatus } from '../../utils/login'
import { AuthorizedStore } from '../authorized/AuthorizedStore'
import { tokenStore } from '../JwtTokenStore'
import type { LinkingStore } from '../linking/LinkingStore'
import type { LocalFeatureOverridesStorage } from '../LocalFeatureOverrideStore'
import type { SdkClientStore } from '../SdkClientStore'
import { tracker } from '../tracker/useTracker'

export class SessionStore {
    authorizedStore?: AuthorizedStore
    loaded = false

    constructor(
        readonly sdk: SdkClientStore,
        private linkingStore: LinkingStore,
        readonly localFeatureOverrideStore: LocalFeatureOverridesStorage
    ) {
        makeAutoObservable(this, {
            phoneVerificationStore: computed({ keepAlive: true }),
            emailVerificationStore: computed({ keepAlive: true }),
            hasVerifiedEmail: computed,
            loggedIn: computed,
            authorizedStore: observable,
            login: action,
            logout: action,
        })
    }

    get loggedIn(): boolean {
        return !!this.authorizedStore?.userStore
    }

    get hasVerifiedEmail(): boolean {
        return !!this.authorizedStore?.userStore?.verifiedEmailAlias
    }

    get phoneVerificationStore() {
        return new PhoneVerificationStore(this.sdk)
    }

    get emailVerificationStore() {
        return new EmailVerificationStore(
            this.sdk,
            mustExist(
                this.authorizedStore?.userStore,
                'userStore required to create EmailVerificationStore'
            )
        )
    }

    restore() {
        transaction(async () => {
            try {
                // means we have authorization saved in LocalStorage
                await this.login()
            } catch (e) {
                // let's clear everything out so we can at least reattempt to login
                this.logout()
            } finally {
                this.loaded = true
            }
        })
    }

    login = async (): Promise<LoginStatus> =>
        transaction(async () => {
            const userId = mustExist(
                tokenStore.refreshToken?.subject,
                'user id should be set in tokenStore to log in'
            )

            if (tokenStore.authLevel === AuthLevel.RESTRICTED) {
                // If we have only a `RESTRICTED` token it means we are only
                // partially authenticated and need to continue recovery to get full access.
                // The SDK will stop the `userStore.load()` request with an error,
                // so we need to push the user to the recovery path.
                return LoginStatus.IN_RECOVERY
            }

            try {
                // Create and load restricted store.
                this.authorizedStore = await AuthorizedStore.create(
                    this,
                    this.linkingStore,
                    this.sdk,
                    this.localFeatureOverrideStore
                )

                tracker.login(userId).then()
                tracker.setAuthorizedStore(this.authorizedStore)
                return LoginStatus.SUCCESS
            } catch (e) {
                if (!(e instanceof Error)) throw e

                if (e instanceof AuthenticationFailureError) {
                    return LoginStatus.FAIL
                }

                if (
                    isHttpError(e, {
                        status: ErrorStatus.PERMISSION_DENIED,
                        code: ErrorCode.VERIFIED_PHONE_REQUIRED,
                    })
                ) {
                    return LoginStatus.NO_PHONE
                }

                throw e
            }
        })

    logout = () =>
        transaction(() => {
            tokenStore.clearStorage()
            this.authorizedStore = undefined
        })
}
