import { ErrorStatus, isHttpError } from '@getstep/sdk/dist/http/HttpError'
import { AuthStore } from '@getstep/sdk/dist/store/auth/AuthStore'
import type { UserStore } from '@getstep/sdk/dist/store/UserStore'
import { action, computed, observable } from 'mobx'

import { maskEmail } from '../../utils/email'
import { OtpVerificationStatus } from '../../utils/login'
import { tokenStore } from '../JwtTokenStore'
import {
    makePersistentObservable,
    PersistentStore,
} from '../persistence/PersistentStore'
import type { SdkClientStore } from '../SdkClientStore'

// TODO: move common code to SDK

export class EmailVerificationStore extends PersistentStore {
    static readonly className = 'EmailVerificationStore'
    static readonly persistentFields = ['valueToVerify', 'next']

    valueToVerify?: string

    next?: string

    canResendCode = true

    constructor(private sdk: SdkClientStore, private userStore: UserStore) {
        super()
        makePersistentObservable(this, {
            canResendCode: observable,
            maskedEmail: computed,
            startVerification: action,
            completeVerification: action,
        })
    }

    get maskedEmail() {
        const { valueToVerify } = this
        if (!valueToVerify) return valueToVerify
        return maskEmail(valueToVerify)
    }

    /**
     * Values for PersonalizationProvider
     */
    getPersonalizations(mounted = false) {
        const { maskedEmail } = this

        return {
            value: maskedEmail,
            email: mounted || !maskedEmail ? '' : maskedEmail,
        }
    }

    startVerification = async (email: string, next?: string) => {
        const { userStore } = this
        this.valueToVerify = email
        if (next) this.next = next
        await userStore.addEmail(email)
        await userStore.startAliasVerification({ email })
        this.persist()
    }

    completeVerification = async (otp: string) => {
        const { valueToVerify, sdk, userStore } = this

        if (!valueToVerify) {
            return { status: OtpVerificationStatus.NO_SESSION } as const
        }

        const authStore = new AuthStore(
            await sdk.createPublicApi(),
            tokenStore,
            tokenStore
        )

        // let's make sure we've got the userId
        await userStore.load()

        try {
            await authStore.verifyLoginFactor(userStore.user.id, otp)
            this.valueToVerify = undefined
            this.clearStorage()
            return { status: OtpVerificationStatus.SUCCESS } as const
        } catch (e) {
            if (!(e instanceof Error)) throw e
            if (isHttpError(e, { status: ErrorStatus.UNAUTHENTICATED })) {
                return { status: OtpVerificationStatus.FAIL } as const
            }

            if (isHttpError(e)) {
                return {
                    status: OtpVerificationStatus.ERROR,
                    error: e,
                } as const
            }

            throw e
        }
    }
}
