export enum LoginStatus {
    SUCCESS = 'SUCCESS',
    NO_USER = 'NO_USER',
    NO_PHONE = 'NO_PHONE',
    IN_RECOVERY = 'IN_RECOVERY',
    IN_PROGRESS = 'IN_PROGRESS',
    FAIL = 'FAIL',
}

export enum PhoneVerificationStatus {
    SUCCESS,
    SECOND_FACTOR_REQUIRED,
    NOT_SUPPORTED,
    ERROR,
}

export enum OtpVerificationStatus {
    SUCCESS,
    FAIL,
    NO_SESSION,
    ERROR,
}
