import { KycError } from '@getstep/proto/common'
import type { Address } from '@getstep/proto/common'
import type { FieldPath } from 'react-hook-form'

export const KYC_ERROR_MATCHERS: Partial<
    Record<KycError, FieldPath<KycFormValues>>
> = {
    [KycError.ADDRESS_INVALID]: 'address.line1',
    [KycError.SSN_INVALID]: 'lastFourSsn',
    [KycError.NAME_INVALID]: 'personalInfo.firstName',
    [KycError.DOB_INVALID]: 'personalInfo.dateOfBirth',
}

export enum PerformKycStatus {
    COMPLETED = 'COMPLETED',
    ATTEMPTED = 'ATTEMPTED',
    TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
    INVALID = 'INVALID',
    ALREADY_KYCED = 'ALREADY_KYCED',
    ERROR = 'ERROR',
    COMPLETED_WITHOUT_INVITE = 'COMPLETED_WITHOUT_INVITE',
}

export interface PersonalInfo {
    firstName: string
    lastName: string
    dateOfBirth: string
}

export interface KycFormValues {
    address: Address
    personalInfo: PersonalInfo
    ssn?: string
    lastFourSsn?: string
}
