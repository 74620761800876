import { ErrorStatus, isHttpError } from '@getstep/sdk/dist/http/HttpError'
import { AuthStore } from '@getstep/sdk/dist/store/auth/AuthStore'
import { mustExist } from '@getstep/sdk/dist/util/Assert'
import { action, observable } from 'mobx'

import { digitsOnly } from '../../../shared/util/phone'
import {
    OtpVerificationStatus,
    PhoneVerificationStatus,
} from '../../utils/login'
import { TEXT } from '../../utils/text'
import { DeviceStore } from '../DeviceStore'
import { tokenStore } from '../JwtTokenStore'
import {
    makePersistentObservable,
    PersistentStore,
} from '../persistence/PersistentStore'
import type { SdkClientStore } from '../SdkClientStore'

// TODO: move common code to SDK

export class PhoneVerificationStore extends PersistentStore {
    static readonly className = 'PhoneVerificationStore'
    static readonly persistentFields = ['valueToVerify', 'otpSessionId']

    valueToVerify?: string

    otpSessionId?: string

    canResendCode = true

    constructor(private sdk: SdkClientStore) {
        super()
        makePersistentObservable(this, {
            canResendCode: observable,
            startVerification: action,
            completeVerification: action,
            setValueToVerify: action,
        })
    }

    /**
     * Values for PersonalizationProvider
     */
    get personalizations() {
        const { valueToVerify } = this
        if (!valueToVerify) return { value: TEXT.yourPhone, phone: '' }
        return { value: valueToVerify, phone: valueToVerify }
    }

    startVerification = async (phone: string) => {
        this.valueToVerify = phone

        const { sdk } = this

        const authStore = new AuthStore(
            await sdk.createPublicApi(),
            tokenStore,
            tokenStore
        )

        try {
            const phoneDigits = await digitsOnly(phone)

            const {
                id: otpSessionId,
                autoLoginPassword,
                ttlMs,
            } = await authStore.startOnboarding(phoneDigits || '')

            this.otpSessionId = otpSessionId

            setTimeout(() => {
                this.valueToVerify = undefined
                this.otpSessionId = undefined
            }, ttlMs)

            if (autoLoginPassword) {
                const { auth } = await this.completeVerification(
                    autoLoginPassword
                )

                return {
                    status: PhoneVerificationStatus.SUCCESS,
                    auth: mustExist(
                        auth,
                        `${this.className}.completeVerification didn't return auth, as expected`
                    ),
                } as const
            }
        } catch (e) {
            if (!(e instanceof Error)) throw e

            if (isHttpError(e, { status: ErrorStatus.FAILED_PRECONDITION })) {
                return {
                    status: PhoneVerificationStatus.NOT_SUPPORTED,
                } as const
            }

            if (isHttpError(e)) {
                return {
                    status: PhoneVerificationStatus.ERROR,
                    error: e,
                } as const
            }
        }

        return {
            status: PhoneVerificationStatus.SECOND_FACTOR_REQUIRED,
        } as const
    }

    completeVerification = async (otp: string) => {
        const { otpSessionId, valueToVerify, sdk } = this
        const authStore = new AuthStore(
            await sdk.createPublicApi(),
            tokenStore,
            tokenStore
        )
        if (!otpSessionId || !valueToVerify) {
            return {
                status: OtpVerificationStatus.NO_SESSION,
            } as const
        }

        try {
            const device = await DeviceStore.fingerprint()
            const auth = await authStore.continueOnboarding(
                otpSessionId,
                { password: otp },
                device
            )

            this.clearStorage()
            return { status: OtpVerificationStatus.SUCCESS, auth } as const
        } catch (e) {
            if (!(e instanceof Error)) throw e

            if (isHttpError(e, { status: ErrorStatus.UNAUTHENTICATED })) {
                return { status: OtpVerificationStatus.FAIL } as const
            }

            if (isHttpError(e)) {
                return {
                    status: OtpVerificationStatus.ERROR,
                    error: e,
                } as const
            }

            throw e
        }
    }

    setValueToVerify = (value: string) => {
        this.valueToVerify = value
    }
}
