import differenceInYears from 'date-fns/differenceInYears';
import isPast from 'date-fns/isPast';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import type { TextInputProps } from '../text-input';
import { TextInput } from '../text-input';
import styles from './date-input.module.scss';
export const DATE_INPUT_FORMAT = 'MM / dd / yyyy';
/**
 * Suuuuper simple date input, without any calendars or anything.
 */
export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(({
  size,
  ...restOfProps
}, ref) => <NumberFormat {...restOfProps} size={size} type='text' getInputRef={ref} format='## / ## / ####' placeholder='MM / DD / YYYY' mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']} customInput={TextInput} className={styles.input} autoComplete='off' allowEmptyFormatting={false} />);
export const fromDateInput = (date: string) => {
  return parse(date, DATE_INPUT_FORMAT, new Date());
};
export const validateDateInput = (date: string) => {
  return isValid(fromDateInput(date));
};
export const AGE_RANGES = {
  child: [0, 13],
  // Young enough that there's some restrictions, <13 (<16 for Google services)
  teen: [13, 18],
  // Old enough to do normal teen stuff in the app, <18
  adult: [18, Infinity] // Legally independent adult, 18+
} as const;
export type AgeType = keyof typeof AGE_RANGES;
export const validateAgeAtLeast = (date: string, value: AgeType | number) => {
  const age = differenceInYears(new Date(), fromDateInput(date));
  return age >= (typeof value === 'number' ? value : AGE_RANGES[value][0]);
};
export const validateAgeAtMost = (date: string, value: AgeType | number) => {
  const age = differenceInYears(new Date(), fromDateInput(date));
  return age <= (typeof value === 'number' ? value : AGE_RANGES[value][1]);
};
export const validateAgeIsExactly = (date: string, value: number) => {
  const age = differenceInYears(new Date(), fromDateInput(date));
  return age === value;
};
export const isDateInputInThePast = (date: string) => {
  return isPast(fromDateInput(date));
};
export type DateInputProps = Omit<TextInputProps, 'type'>;