import { DefaultAccessTokenProvider } from '@getstep/sdk'
import { Api } from '@getstep/sdk/dist/http/Api'
import { PublicApi } from '@getstep/sdk/dist/http/PublicApi'
import { ExternalAccountsStore } from '@getstep/sdk/dist/store/ExternalAccountsStore'
import { FamilyStore } from '@getstep/sdk/dist/store/FamilyStore'
import { FeatureStore } from '@getstep/sdk/dist/store/FeatureStore'
import { TransferStore } from '@getstep/sdk/dist/store/transfer/TransferStore'
import { UserStore } from '@getstep/sdk/dist/store/UserStore'
import { mustExist } from '@getstep/sdk/dist/util/Assert'

import { tokenStore } from '../JwtTokenStore'
import type { LinkingStore } from '../linking/LinkingStore'
import type { LocalFeatureOverridesStorage } from '../LocalFeatureOverrideStore'
import type { SdkClientStore } from '../SdkClientStore'
import type { SessionStore } from '../session'
import { KYCStore } from './KYCStore'
import { SponsorRequestStore } from './SponsorRequestStore'

/**
 * These stores require a logged in user to function.
 */
export class AuthorizedStore {
    constructor(
        readonly sessionStore: SessionStore,
        readonly familyStore: FamilyStore,
        readonly userStore: UserStore,
        readonly sponsorRequestStore: SponsorRequestStore,
        readonly kycStore: KYCStore,
        readonly transferStore: TransferStore,
        readonly externalAccountsStore: ExternalAccountsStore,
        readonly featureStore: FeatureStore
    ) {}

    static async create(
        sessionStore: SessionStore,
        linkingStore: LinkingStore,
        sdk: SdkClientStore,
        featureOverideStores: LocalFeatureOverridesStorage
    ) {
        const refreshToken = mustExist(
            tokenStore.refreshToken,
            'refreshToken is required to create AuthorizedStore'
        )

        const apiClient = await sdk.createHttpClient()
        const stripeHttpClient = await sdk.createStripeHttpClient()
        const publicApi = new PublicApi(apiClient)

        const tokenProvider = new DefaultAccessTokenProvider(publicApi, {
            refreshToken,
        })

        const api = new Api(apiClient, tokenStore, tokenStore, tokenProvider)

        const familyStore = new FamilyStore(apiClient, api)
        const userStore = new UserStore(api)
        const sponsorRequestStore = new SponsorRequestStore(
            familyStore,
            linkingStore,
            userStore
        )
        const transferStore = new TransferStore(api)

        const externalAccountsStore = new ExternalAccountsStore(
            api,
            stripeHttpClient
        )

        const featureStore = new FeatureStore(api, featureOverideStores)

        // load userStore and featureStore in all cases because its likely to be used in all authorized screens
        await Promise.all([userStore.load(), featureStore.load()])

        return new AuthorizedStore(
            sessionStore,
            familyStore,
            userStore,
            sponsorRequestStore,
            new KYCStore(sessionStore, sponsorRequestStore),
            transferStore,
            externalAccountsStore,
            featureStore
        )
    }
}
