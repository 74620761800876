import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import { iconSizeSmall, iconSizeXSmall } from '../../../styles/variables.module.scss';
import { Lock } from '../../icons/lock';
import type { TextInputProps } from '../text-input';
import { TextInput } from '../text-input';
import styles from './full-ssn-input.module.scss';
export const SSN_LENGTH = 11;
export const SSN_LENGTH_WITHOUT_DELIMTERS = 9;

/**
 * Lets you input full ssn digits.
 */
export const FullSsnInput = forwardRef<HTMLInputElement, FullSsnInputProps>(({
  size,
  placeholder = 'xxx-xx-xxxx',
  ...restOfProps
}, ref) => <NumberFormat {...restOfProps} size={size} allowLeadingZeros type='text' getInputRef={ref} format='###-##-####' placeholder={placeholder} customInput={TextInput} className={styles.input} allowEmptyFormatting={false} adornment={{
  left: '',
  right: <Lock color='step-cold-steel' width={iconSizeXSmall} height={iconSizeSmall} />
}} />);
export type FullSsnInputProps = Omit<TextInputProps, 'type'>;