import { KycError, KycStatus } from '@getstep/proto/common'
import type { Address } from '@getstep/proto/common'
import type { User } from '@getstep/proto/user'
import {
    ErrorCode,
    ErrorStatus,
    isHttpError,
} from '@getstep/sdk/dist/http/HttpError'
import { mustExist } from '@getstep/sdk/dist/util/Assert'
import { captureException } from '@sentry/react'
import { formatISO } from 'date-fns'
import { action, computed } from 'mobx'
import type { FieldPath } from 'react-hook-form'

import { fromDateInput } from '../../../components/input/date-input'
import { SSN_LENGTH_WITHOUT_DELIMTERS } from '../../../components/input/full-ssn-input'
import type { KycFormValues, PersonalInfo } from '../../utils/kyc'
import { KYC_ERROR_MATCHERS, PerformKycStatus } from '../../utils/kyc'
import { digitsOnly } from '../../utils/number'
import {
    makePersistentObservable,
    PersistentStore,
} from '../persistence/PersistentStore'
import type { SessionStore } from '../session'
import { tracker } from '../tracker/useTracker'
import type { SponsorRequestStore } from './SponsorRequestStore'

// TODO: move common code to SDK

export class KYCStore extends PersistentStore {
    static readonly className = 'KYCStore'
    static readonly persistentFields = [
        'address',
        'personalInfo',
        'lastFourSsn',
        'thinFile',
    ]

    address: Address = {
        city: '',
        stateProvinceRegion: '',
        zipPostalCode: '',
        line1: '',
        line2: '',
        country: '',
    }

    personalInfo: PersonalInfo = {
        lastName: '',
        firstName: '',
        dateOfBirth: '',
    }

    ssn = ''
    lastFourSsn = ''
    thinFile = false

    constructor(
        private session: SessionStore,
        private sponsorRequestStore: SponsorRequestStore
    ) {
        super()
        makePersistentObservable(this, {
            defaultValues: computed,
            userStore: computed,
            performKYC: action,
            setValues: action,
        })
    }

    get defaultValues() {
        return {
            lastFourSsn: this.lastFourSsn,
            address: this.address,
            personalInfo: this.personalInfo,
            ssn: this.ssn,
        }
    }

    get userStore() {
        return mustExist(
            this.session.authorizedStore?.userStore,
            `expected ${this.className}.userStore to be defined, but it wasn't`
        )
    }

    /**
     * Helper to check if user is over 18.
     */
    get isOver18(): boolean {
        const dob = this.personalInfo.dateOfBirth
        if (!dob) return false

        const [month, day, year] = dob.split('/').map((s) => parseInt(s, 10))

        const birthDate = new Date(year, month, day)
        const eigheteenYearsAgo = new Date()
        eigheteenYearsAgo.setFullYear(eigheteenYearsAgo.getFullYear() + 18)

        return birthDate < eigheteenYearsAgo
    }

    /**
     * Helper to check if user is KYC'ed or not.
     */
    get isKYCed(): boolean {
        return this.userStore.isKYCed
    }

    /**
     * Helper to check if user is in manual KYC review or has failed KYC.
     */
    get isKYCPending(): boolean {
        return this.userStore.isKYCPending
    }

    /**
     * Helper to check if user is eligible for KYC.
     */
    get isKYCEligible(): boolean {
        return this.isOver18 && !this.isKYCPending && !this.isKYCed
    }

    /**
     * Helper to check if user has a full SSN.
     */
    get hasFullSsn(): boolean {
        return this.userStore.ssnType === 'full'
    }

    async setSsn(ssn?: string): Promise<User | undefined> {
        this.ssn = ssn ? digitsOnly(ssn) : ''
        this.lastFourSsn = ''

        if (this.ssn?.length === SSN_LENGTH_WITHOUT_DELIMTERS) {
            this.lastFourSsn = this.ssn.slice(-4)
            return this.userStore.setSsn(this.ssn)
        }

        return
    }

    /**
     * Updates user with provided data and attempts to perform KYC
     * TODO: maaaaaaaybe these three operations could be performed in one HTTP request
     */
    performKYC = async (values: KycFormValues) => {
        const {
            address,
            lastFourSsn,
            personalInfo: { lastName, firstName, dateOfBirth },
            ssn,
        } = values

        const { userStore, sponsorRequestStore } = this

        await sponsorRequestStore.load()

        // First, let's populate the user
        try {
            const ssnDigits = ssn ? digitsOnly(ssn) : undefined
            await userStore.updateUser({
                legalName: { lastName, firstName },
                address: { ...address, country: 'US' },
                lastFourSsn,
                dob: formatISO(fromDateInput(dateOfBirth), {
                    representation: 'date',
                }),
                ssn: ssnDigits,
            })
        } catch (e) {
            captureException(e, {
                tags: {
                    errorType: 'KYCStore.performKYC.updateUser',
                },
            })

            if (!(e instanceof Error)) throw e

            if (isHttpError(e, { status: ErrorStatus.RESOURCE_EXHAUSTED })) {
                return { status: PerformKycStatus.TOO_MANY_ATTEMPTS as const }
            }

            if (isHttpError(e, { status: ErrorStatus.FAILED_PRECONDITION })) {
                if (this.isKYCed) {
                    return { status: PerformKycStatus.ALREADY_KYCED as const }
                }

                // handle: KYC details cannot be updated while under manual review error
                return {
                    status: PerformKycStatus.INVALID as const,
                    error: e,
                    kyc: KycStatus.PROCESSING,
                }
            }

            if (
                isHttpError(e, {
                    status: ErrorStatus.INVALID_ARGUMENT,
                    code: 'ADDRESS_UNSUPPORTED_STATE' as unknown as ErrorCode,
                })
            ) {
                return {
                    status: PerformKycStatus.ATTEMPTED as const,
                    fields: ['address.stateProvinceRegion'] as const,
                }
            }

            if (
                isHttpError(e, {
                    status: ErrorStatus.INVALID_ARGUMENT,
                    code: ErrorCode.INVALID_DOB,
                })
            ) {
                return {
                    status: PerformKycStatus.ATTEMPTED as const,
                    fields: ['personalInfo.dateOfBirth'] as const,
                }
            }

            if (isHttpError(e)) {
                return { status: PerformKycStatus.ERROR as const, error: e }
            }

            throw e
        }

        // Second, let's KYC said user
        try {
            const { errors, kycStatus } = await userStore.onboard(true)

            if (kycStatus === KycStatus.ATTEMPTED) {
                return {
                    status: PerformKycStatus.ATTEMPTED as const,
                    fields: errors
                        .map((e) => KYC_ERROR_MATCHERS[e])
                        .filter(Boolean) as FieldPath<KycFormValues>[],
                }
            }
            if (kycStatus !== KycStatus.SUCCESS) {
                // check if user has a thin file so we know to collect full SSN or not
                this.thinFile = errors.includes(KycError.THIN_FILE)

                return {
                    status: PerformKycStatus.INVALID as const,
                    kyc: kycStatus,
                }
            }

            tracker.kycSuccess()
            this.clearStorage()

            if (!sponsorRequestStore.hasPendingInvites) {
                return {
                    status: PerformKycStatus.COMPLETED_WITHOUT_INVITE as const,
                    kyc: KycStatus.SUCCESS,
                }
            }
        } catch (e) {
            captureException(e, {
                tags: {
                    errorType: 'KYCStore.performKYC.onboard',
                },
            })

            if (!(e instanceof Error)) throw e
            if (isHttpError(e)) {
                return { status: PerformKycStatus.ERROR as const, error: e }
            }

            throw e
        }

        // Third, let's accept user's last pending invite
        try {
            await this.sponsorRequestStore.acceptInvite()
        } catch (e) {
            captureException(e, {
                tags: {
                    errorType: 'KYCStore.performKYC.acceptInvite',
                },
            })

            if (!(e instanceof Error)) throw e
            if (isHttpError(e)) {
                return { status: PerformKycStatus.ERROR as const, error: e }
            }
            throw e
        }

        return {
            status: PerformKycStatus.COMPLETED as const,
            kyc: KycStatus.SUCCESS,
        }
    }

    setValues = (values: Partial<KycFormValues>) => {
        Object.assign(this, values)
        this.persist()
    }
}
