import type { AuthLevel } from '@getstep/proto-lib/rpc'
import type {
    AccessTokenStorage,
    TokenSettings,
} from '@getstep/sdk/dist/http/auth'
import { AuthToken } from '@getstep/sdk/dist/http/models/AuthToken'
import { runInAction } from 'mobx'

import {
    makePersistentObservable,
    PersistentStore,
} from './persistence/PersistentStore'

/**
 * Saves and loads JWT Access Tokens.
 */
class JwtTokenStore
    extends PersistentStore
    implements AccessTokenStorage, TokenSettings
{
    static readonly className = 'JwtTokenStore'
    static readonly persistentFields = ['refreshTokenProps', 'clockSkew']

    refreshTokenProps: TokenProps | Record<string, never> = {}

    /**
     * Clock skew in milliseconds between client and server. Used for tokens `exp` date generation.
     */
    clockSkew?: number

    get tokens() {
        return { refreshToken: this.refreshTokenProps }
    }

    get authLevel() {
        return this.refreshToken?.authLevel
    }

    get refreshToken(): AuthToken | undefined {
        if (!('value' in this.refreshTokenProps)) return undefined
        return JwtTokenStore.deserialize(this.refreshTokenProps as TokenProps)
    }

    set refreshToken(token: AuthToken | undefined) {
        runInAction(
            () =>
                (this.refreshTokenProps = JwtTokenStore.serialize(token) || {})
        )
    }

    static deserialize(props: TokenProps) {
        return new AuthToken(props)
    }

    static serialize(token: AuthToken | undefined): TokenProps | undefined {
        if (!token) return

        const { value, authLevel, expiresAtMs, subject, clockSkew } = token

        return {
            value,
            authLevel,
            expiresAtMs,
            subject,
            clockSkew,
        }
    }
}

export const tokenStore = makePersistentObservable(new JwtTokenStore())

export interface TokenProps {
    value: string
    expiresAtMs: number
    authLevel: AuthLevel
    subject: string
    clockSkew: number
}
