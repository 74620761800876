import type { FamilyStore } from '@getstep/sdk/dist/store/FamilyStore'
import type { UserStore } from '@getstep/sdk/dist/store/UserStore'
import { abbreviatedFullName, splitNames } from '@getstep/sdk/dist/util/Names'
import { PublicProfile } from '@getstep/sdk/dist/util/PublicProfile'
import sortBy from 'lodash/sortBy'
import { makeAutoObservable } from 'mobx'

import type { LinkingStore } from '../linking/LinkingStore'
import { tracker } from '../tracker/useTracker'

export class SponsorRequestStore {
    static className = 'SponsorRequestStore'

    loaded = false

    constructor(
        private familyStore: FamilyStore,
        private linkingStore: LinkingStore,
        private userStore: UserStore
    ) {
        makeAutoObservable(this)
    }

    get sponseeProfile() {
        const sponsee = this.currentInvite?.inviter

        if (!sponsee) return

        const { fullName, pictureUrl, aliases, legalName } = sponsee

        return {
            ...new PublicProfile({
                displayName: partsToName(splitNames(fullName)),
                pictureUrl,
                username: aliases.find((a) => a.username)?.username,
            }),
            legalName,
            userId: aliases.find((a) => a.userId)?.userId,
        }
    }

    /**
     * Returns true if we have at least one pending invite.
     */

    get hasPendingInvites(): boolean {
        return this.pendingInvites.length > 0
    }

    /**
     * Returns true if we're sponsoring someone
     */

    get hasSponsees(): boolean {
        return this.sponsees.length > 0
    }

    /**
     * Returns all sponsored family members except us.
     */

    private get sponsees() {
        return sortBy(this.familyStore.members, (m) => m.user.updatedAtMs)
            .reverse()
            .filter(
                ({ user, group }) =>
                    user.id !== this.userStore.user.id &&
                    group === 'FAMILY_MEMBER'
            )
    }

    /**
     * Returns all invites sent to us.
     */
    private get pendingInvites() {
        const invites = this.familyStore.invitesReceived.filter(
            ({ group }) => group === 'FAMILY_ADMIN'
        )
        return sortBy(invites, (a) => +a.createdAtMs).reverse()
    }

    private get currentInvite() {
        const { pendingInvites, linkingStore } = this

        if (!pendingInvites.length) return

        return (
            pendingInvites.find(
                // TODO: this should probably be matched with id, not name
                (i) =>
                    i.inviter.fullName === linkingStore.data.sponsee_full_name
            ) ?? pendingInvites[0]
        )
    }

    /**
     * Accepts last pending invite.
     */
    acceptInvite = async () => {
        await this.load()
        if (!this.currentInvite) return

        const {
            familyStore,
            currentInvite: { id },
        } = this

        await familyStore.acceptFamilyInvite(id)
        tracker.familyJoined(id)
        await this.refresh()
    }

    async refresh() {
        if (!this.loaded) {
            await Promise.all(
                [this.familyStore, this.userStore].map((e) => e.refresh())
            )
        }
    }

    async load() {
        if (!this.loaded) {
            await Promise.all(
                [this.familyStore, this.userStore].map((e) => e.load())
            )
            this.loaded = true
        }
    }
}

const partsToName = ({ firstName = '', lastName = '' } = {}) =>
    abbreviatedFullName(firstName, lastName)
