import type { BrandColor } from '../../../types/color';
import type { IconComponent } from '../types';

/** `🔒` Icon */
export const Lock: IconComponent<Props> = ({
  color,
  width,
  height,
  ...restOfProps
}) => <svg width={width} height={height} viewBox='0 0 13 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...restOfProps} data-sentry-element="svg" data-sentry-component="Lock" data-sentry-source-file="index.tsx">
        <g opacity='.5' data-sentry-element="g" data-sentry-source-file="index.tsx">
            <path d='M3.305 6.894V4.292C3.305 2.482 4.78 1 6.582 1 8.385 1 9.86 2.483 9.86 4.292v2.711' stroke={`var(--${color}`} strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' data-sentry-element="path" data-sentry-source-file="index.tsx" />
            <path d='M6.59 13.639a1.228 1.228 0 100-2.457 1.228 1.228 0 000 2.457z' fill={`var(--${color}`} data-sentry-element="path" data-sentry-source-file="index.tsx" />
            <path d='M6.59 15.187V12.52' stroke={`var(--${color}`} strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' data-sentry-element="path" data-sentry-source-file="index.tsx" />
            <rect x='1' y='8' width='11' height='11' rx='1' stroke={`var(--${color}`} strokeWidth='2' data-sentry-element="rect" data-sentry-source-file="index.tsx" />
        </g>
    </svg>;
interface Props {
  /** Color of the Lock */
  color?: BrandColor;
}